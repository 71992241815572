@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.title-links {
  &--multiple {
    @include above(tablet) {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
