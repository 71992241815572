@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.category {
  $block: &;
  background-color: $silver-grey;
  opacity: 0;
  visibility: hidden;
  transition: none;

  @include above(laptop) {
    background-color: #c1c1c1;
  }

  &__cover {
    position: relative;
  }

  &__cover-img {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    padding: 16px;
    background-size: cover;
    background-repeat: no-repeat;

    @include above(laptop) {
      padding: 24px;
      height: 270px;
    }

    @include above(desktop) {
      height: 320px;
    }
  }

  &__title {
    position: relative;
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: $iceberg;

    @include above(laptop) {
      line-height: 1.5625rem;
    }
  }
}
