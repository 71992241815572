@import "../../styles/tools/layout/container";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.media {
  &__container {
    @include container;
    position: relative;
    margin-top: -53px;
    margin-bottom: 48px;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include above(tablet) {
      margin-top: -92px;
      margin-bottom: 64px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__watermark {
    position: absolute;
    top: 92px;
    left: 0;
    min-height: 100%;
    min-width: 100%;
  }

  &__blog-list {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    min-height: 245px;

    @include above(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
    }

    @include above(laptop) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 40px;
    }
  }

  &__load-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include above(tablet) {
      margin-top: 100px;
      grid-column: span 2;
    }

    @include above(laptop) {
      grid-column: span 3;
    }

    &--more {
      margin-top: 0;
    }
  }

  &__loader {
    display: block;
    height: 200px;
    width: 200px;

    &--light {
      background: blue;
    }
  }

  &__button {
    margin-top: 24px;
    padding: 12px 18px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    text-transform: capitalize;

    @include above(laptop) {
      position: relative;
      margin-top: 40px;
      background-color: white;
    }
  }

  &__error {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 24px;
    background-color: $cross-vector-light;

    @include above(tablet) {
      grid-column: span 2;
    }

    @include above(laptop) {
      grid-column: span 3;
    }
  }

  &__error-message {
    font-family: $made-tommy-bold;
    text-align: center;
  }
}
