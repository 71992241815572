@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.partner {
  &__link {
    display: block;
  }

  &__img {
    display: block;
    max-height: 78px;
    max-width: calc(50vw - 30px);
    margin-left: auto;
    margin-right: auto;

    @include above(tablet) {
      max-height: 125px;
    }

    @include above(laptop) {
      max-width: 16vw;
      transition: transform 0.2s linear;

      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }

    @include above(desktop) {
      max-width: 20vw;
    }
  }
}
