@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.axis-parallax {
  &__container {
    position: absolute;
    top: var(--topPos, 114px);
    right: 0;
    height: 896px;
    width: 39px;

    @include above(desktop) {
      top: var(--topDeskPos, 64px);
      right: var(--rightDeskPos, 45px);
    }
  }

  &__layer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    color: $iceberg;

    &--dark {
      color: black;
    }

    @media (prefers-reduced-motion: reduce) {
      transform: translateY(0) !important;
    }
  }
}
