@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.hero-mask {
  $block: &;
  position: relative;
  grid-area: mask;
  width: 100%;
  overflow: hidden;

  @include above(desktop) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 50px;
      width: calc(50% - 710px);
      background-color: white;
    }

    &::after {
      right: 0;
    }

    &--dark {
      &::before,
      &::after {
        background-color: $arrow-partial;
      }
    }
    &--black {
      &::before,
      &::after {
        background-color: black;
      }
    }
  }

  &__cross-vector {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    color: black;
  }

  &__mask {
    position: absolute;
    left: 50%;
    bottom: 0;
    color: $iceberg;
    transform: translateX(-50%);

    #{$block}--dark & {
      color: $arrow-partial;
    }

    #{$block}--black & {
      color: black;
    }
  }
}
