@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.social-list {
  $block: &;

  &__title {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    @include above(laptop) {
      text-align: left;
    }

    #{$block}--dark & {
      font-family: $made-tommy-black;
      font-weight: 900;
      font-size: 1rem;
      line-height: 1.25rem;
      text-align: left;
    }
  }

  &__container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    @include above(laptop) {
      margin-top: 32px;
    }

    #{$block}--dark & {
      margin-top: 37.5px;

      @include above(tablet) {
        margin-top: 32px;
      }
    }

    #{$block}--no-spacing & {
      margin-top: 0;
    }
  }

  &__link,
  &__img {
    display: block;
  }
}
