@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.culture-title {
  $block: &;
  padding-top: 64px;

  @include above(tablet) {
    padding-top: 0;
  }

  &__background {
    position: relative;

    @include above(tablet) {
      padding-top: 52px;
    }

    @include above(desktop) {
      padding-top: 102px;
    }
  }

  &__container {
    @include container;
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    color: $topographic-square-original;

    @include above(tablet) {
      max-width: 45vw;
      margin-left: auto;
      margin-right: auto;
      color: black;
    }

    @include above(laptop) {
      max-width: 61.9vw;
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-size: 4rem;
      line-height: 3.5rem;
      font-family: $made-tommy-black;
      font-weight: 800;
    }

    @include above(maxwidth) {
      max-width: 1064px;
    }
  }

  &__arrow {
    position: relative;
  }

  &__down-vector {
    position: absolute;
    bottom: -60px;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    z-index: 2;

    @include above(tablet) {
      bottom: -80px;
    }

    @include above(laptop) {
      height: 80px;
      width: auto;
      bottom: -120px;
    }

    @include above(desktop) {
      height: 111px;
      bottom: -181px;
    }
  }

  &__diamond {
    position: absolute;
    opacity: 0.2;
    top: -300px;
    z-index: 1;

    &--left {
      left: 0px;
      transform: translateX(-70%);
    }

    &--right {
      right: 0px;
      transform: scaleX(-1) translateX(-70%);
    }

    @include above(tablet) {
      top: 0;
      height: 769px;

      &--left {
        transform: none;
      }

      &--right {
        transform: scaleX(-1);
      }
    }

    @include above(laptop) {
      top: 200px;
    }

    @include above(desktop) {
      height: 1126px;
      top: 70px;
    }
  }
}
