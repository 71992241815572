@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.mask-right {
  $block: &;
  grid-area: mask;
  position: relative;
  z-index: 1;

  &__mask {
    position: absolute;
    top: calc((100% - 108px) / 2);
    right: -57px;
    transform: translateY(25%);
    color: $iceberg;
    z-index: 1;

    #{$block}--dark & {
      color: $arrow-partial;
    }
  }
}
