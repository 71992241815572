@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.job-apply {
  $block: &;
  margin-top: 28px;

  #{$block}__link {
    display: inline-block;
    width: 100%;

    @include above(tablet) {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
