@font-face {
  font-family: "made_tommyblack";
  src: url("./made_tommy_black-webfont.woff2") format("woff2"),
    url("./made_tommy_black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "made_tommybold";
  src: url("./made_tommy_bold-webfont.woff2") format("woff2"),
    url("./made_tommy_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "made_tommymedium";
  src: url("./made_tommy_medium-webfont.woff2") format("woff2"),
    url("./made_tommy_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "made_tommyregular";
  src: url("./made_tommy_regular-webfont.woff2") format("woff2"),
    url("./made_tommy_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "made_tommylight";
  src: url("./made_tommy_light-webfont.woff2") format("woff2"),
    url("./made_tommy_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
