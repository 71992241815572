@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.value-list {
  position: relative;
  background: black;

  &__container {
    @include container;
    position: relative;
    padding-top: 89px;
    padding-bottom: 73px;
    overflow: hidden;

    @include above(tablet) {
      padding-left: 84px;
      padding-right: 84px;
    }

    @include above(laptop) {
      padding-top: 159px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 141px;
    }

    @include above(desktop) {
      padding-left: 111px;
      padding-right: 111px;
    }
  }

  &__topography,
  &__watermark {
    position: absolute;
    top: 0;
    min-height: 100%;
    min-width: 100%;
  }

  &__topography {
    left: 0;
    color: $topography-value-list;
    opacity: 0.5;
  }

  &__watermark {
    color: $watermark-value-list;
    left: 50%;
    transform: translateX(-50%);

    @include above(maxwidth) {
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &__content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 52px;

    @include above(laptop) {
      row-gap: 72px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
      padding: 0 111px;
    }
  }

  &__value {
    font-family: $made-tommy-light;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: $iceberg;
    opacity: 0;
    visibility: hidden;
    transition: none;

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(7),
    &:nth-child(9) {
      text-align: right;
    }

    &:nth-child(5) {
      grid-column: span 2;
      text-align: center;
    }

    &:nth-child(3) {
      padding-left: 10.7vw;
    }
    &:nth-child(4) {
      padding-right: 10.7vw;
    }

    &:nth-child(6) {
      padding-left: 13.6vw;
    }
    &:nth-child(7) {
      padding-right: 13.6vw;
    }

    &:nth-child(8) {
      padding-left: 4.5vw;
    }
    &:nth-child(9) {
      padding-right: 4.5vw;
    }

    @include above(tablet) {
      &:nth-child(3) {
        padding-left: 13vw;
      }
      &:nth-child(4) {
        padding-right: 13vw;
      }

      &:nth-child(6) {
        padding-left: 14.4vw;
      }
      &:nth-child(7) {
        padding-right: 14.4vw;
      }

      &:nth-child(8) {
        padding-left: 2.2vw;
      }
      &:nth-child(9) {
        padding-right: 2.2vw;
      }
    }

    @include above(laptop) {
      font-size: 2.25rem;
      line-height: 2.8125rem;

      &:nth-child(3) {
        padding-left: 13.96vw;
      }
      &:nth-child(4) {
        padding-right: 13.96vw;
      }

      &:nth-child(6) {
        padding-left: 16.2vw;
      }
      &:nth-child(7) {
        padding-right: 16.2vw;
      }

      &:nth-child(8) {
        padding-left: 3vw;
      }
      &:nth-child(9) {
        padding-right: 3vw;
      }
    }

    @include above(desktop) {
      &:nth-child(3) {
        padding-left: 16.1vw;
      }
      &:nth-child(4) {
        padding-right: 16.1vw;
      }

      &:nth-child(6) {
        padding-left: 17.7vw;
      }
      &:nth-child(7) {
        padding-right: 17.7vw;
      }

      &:nth-child(8) {
        padding-left: 2.3vw;
      }
      &:nth-child(9) {
        padding-right: 2.3vw;
      }
    }

    @include above(desktop) {
      &:nth-child(3) {
        padding-left: 232px;
      }
      &:nth-child(4) {
        padding-right: 232px;
      }

      &:nth-child(6) {
        padding-left: 255px;
      }
      &:nth-child(7) {
        padding-right: 255px;
      }

      &:nth-child(8) {
        padding-left: 33px;
      }
      &:nth-child(9) {
        padding-right: 33px;
      }
    }
  }

  &__polygon {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: scaleY(-1) translateX(-50%);
    color: $iceberg;
    z-index: 1;
  }
}
