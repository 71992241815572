@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.hero-overlay {
  $block: &;
  grid-area: 1 / 1 / 6 / 4;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  z-index: -1;

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      #000000 -53.42%,
      rgba(0, 0, 0, 0) 113.38%
    );
  }

  &__watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    opacity: 0.6;
    color: white;
  }

  &__cross-vector {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    color: black;
  }

  &__image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 400vw !important;
    min-height: 120vh !important;
    transform: translateX(-50%) translateY(-50%);

    @include above(tablet) {
      min-width: 200vw !important;
      min-height: 100vh !important;
    }

    @include above(laptop) {
      min-width: 120vw !important;
      min-height: 120vh !important;
    }

    @include above(desktop) {
      min-width: 150vw !important;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      min-height: 160vh !important;
    }
  }
}
