@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.contact-form {
  margin-top: -50px;
  padding-top: 83px;
  padding-bottom: 69px;
  background: black;

  @include above(tablet) {
    padding-top: 69px;
  }

  @include above(laptop) {
    margin-top: 108px;
    padding-top: 74.5px;
    padding-bottom: 74.5px;
    width: 584px;
  }

  @include above(desktop) {
    width: 50%;
  }

  &__container {
    @include container;
    color: $iceberg;

    @include above(laptop) {
      padding-left: 114px;
      padding-right: 114px;
    }

    @include above(desktop) {
      padding-left: 118px;
      padding-right: 118px;
    }
  }

  &__title {
    font-family: $made-tommy;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.875rem;

    @include above(laptop) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__form {
    margin-top: 16px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    input,
    textarea,
    select {
      font-family: $made-tommy;
      padding: 14px 24px;
      color: #6c6c6c;
    }

    textarea {
      height: 140px;
      resize: none;
    }

    @include above(laptop) {
      margin-top: 24px;
      gap: 24px;

      textarea {
        height: 171px;
      }
    }

    @include above(maxwidth) {
      max-width: 500px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  &__checkbox,
  &__disclaimer,
  &__disclaimer > * {
    font-size: 14px !important;
    line-height: 1 !important;
  }

  &__disclaimer {
    & a {
      &:link,
      &:visited {
        color: white;
        font-weight: 700;
      }

      &:hover,
      &:active {
        color: #00fef0;
      }
    }
  }

  &__button {
    font-family: $made-tommy-medium;
    font-size: 1rem;
    line-height: 1.25rem;

    @include above(tablet) {
      align-self: flex-start;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
