@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/z-index";
@import "../../styles/tools/config/buttons";

.nav-bar {
  $block: &;
  position: absolute;
  display: grid;
  grid:
    [row1-start] "nav logo cta" 1fr [row1-end]
    / 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  padding: 25px 24px;
  overflow: hidden;
  z-index: $z-nav-bar;

  @include above(laptop) {
    grid:
      [row1-start] "logo nav cta" 1fr [row1-end]
      / auto 1fr auto;
    overflow: visible;
    justify-items: flex-end;
    max-width: $maxwidth;
    padding-right: 35px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include above(desktop) {
    padding-left: 80px;
    padding-right: 160px;
    padding-top: 32px;
  }

  @include above(maxwidth) {
    padding-right: 80px;
  }

  &--open {
    overflow: visible;
  }

  &__list-container {
    position: absolute;
    left: 100%;
    top: 0;
    background: black;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s ease;

    &.show {
      position: fixed;
      left: 0%;
      opacity: 1;
      z-index: $z-nav-list-show;

      #{$block}__mobile-overlay {
        display: block;
      }
    }

    @include above(laptop) {
      grid-area: nav;
      position: initial;
      display: block;
      opacity: 1;
      background: transparent;
      overflow: visible;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 40px;
    width: 100vw;
    height: 100vh;
    text-align: center;
    list-style: none;

    @include above(laptop) {
      flex-flow: row;
      gap: 44px;
      width: auto;
      height: 50px;
      margin-right: 60px;
      padding: 0;
      max-height: none;
    }
  }

  &__mobile-overlay {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 250%;
    width: 250%;
    transform: translate(-51%, -51%);
    color: $watermark-mobile;

    @include above(desktop) {
      display: none;
    }
  }

  &__logo-link {
    grid-area: logo;
    justify-self: center;
    align-self: flex-start;

    @include above(laptop) {
      display: flex;
      align-self: flex-end;
    }
  }

  &__logo {
    #{$block}--dark & {
      path {
        fill: black;
      }
    }
  }

  &__cta {
    @include button-minimal;
    grid-area: cta;
    justify-self: flex-end;
    padding: 12px;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    border: 1px solid $iceberg;

    #{$block}--dark & {
      color: black;
      border-color: black;

      &:hover,
      &:focus {
        background-color: black;
        color: $iceberg;
      }
    }
  }
}
