@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.trusted-partners {
  padding-top: 40px;
  padding-bottom: 40px;

  @include above(tablet) {
    padding-bottom: 70px;
  }

  @include above(laptop) {
    padding-top: 64px;
  }

  @include above(desktop) {
    padding-bottom: 120px;
  }

  &__container {
    @include container;
    margin-top: 32px;
    max-width: $maxwidth;

    @include above(tablet) {
      margin-top: 0;
    }

    @include above(laptop) {
      margin-top: 50px;
    }

    @include above(desktop) {
      margin-top: 55px;
    }
  }
}
