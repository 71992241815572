@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.scroll-indicator {
  position: relative;
  grid-area: scroll;
  justify-self: center;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 24px;
  height: 40px;
  padding-bottom: 9px;
  border: 2px solid $cross-vector-light;
  border-radius: 10px;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background: $cross-vector-light;
    border-radius: 4px;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  75%,
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}
