@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.mask-bottom {
  $block: &;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  @include above(desktop) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 50px;
      width: calc(50% - 710px);
      background-color: white;
    }

    &::after {
      right: 0;
    }

    &--dark {
      &::before,
      &::after {
        background-color: $arrow-partial;
      }
    }
    &--black {
      &::before,
      &::after {
        background-color: black;
      }
    }
    &--invert {
      &::before,
      &::after {
        height: 49px;
      }
    }
  }

  &--invert {
    bottom: -50px;
  }

  &__cross-vector {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    color: black;
  }

  &__mask {
    position: absolute;
    left: 50%;
    bottom: 0;
    color: $iceberg;
    transform: translateX(-50%);

    #{$block}--invert & {
      transform: translateX(-50%) scaleY(-1);
    }

    #{$block}--dark & {
      color: $arrow-partial;
    }
  }
}
