@import "../config/breakpoints";
@import "../media-queries/above";
@import "../../core/scss-variables";

@mixin container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $mobile-gutter;
  padding-right: $mobile-gutter;

  @include above(tablet) {
    padding-left: $tablet-gutter;
    padding-right: $tablet-gutter;
  }

  @include above(laptop) {
    padding-left: $laptop-gutter;
    padding-right: $laptop-gutter;
  }

  @include above(desktop) {
    padding-left: $desktop-gutter;
    padding-right: $desktop-gutter;
  }
}
