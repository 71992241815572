@import "../../styles/tools/functions/breakpoint-value";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.chevron-parallax {
  $block: &;
  position: relative;
  width: 100%;
  z-index: 2;

  &--using-parallax {
    @include above(laptop) {
      display: flex;
      position: sticky;
      top: 0;
    }
  }

  &__vector {
    position: absolute;
    bottom: -60px;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    z-index: 2;

    @include above(laptop) {
      height: 80px;
      width: auto;
      bottom: -120px;
    }

    @include above(desktop) {
      height: 111px;
      bottom: -181px;
    }

    #{$block}--alt & {
      @include above(desktop) {
        bottom: -151px;
      }
    }
  }

  &__content {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    mask-image: var(--maskUrl);
    mask-size: 20vmin;
    mask-repeat: no-repeat;
    mask-position: center 20px;

    @include above(tablet) {
      mask-size: 10vmin;
    }

    @include above(laptop) {
      min-width: 100%;
      width: auto;
      min-height: 1000px;
      mask-size: 15vmin;
      mask-position: center top;
      transition: mask-position 0.5s ease-out;

      &--active {
        mask-size: clamp(
          15vmin,
          var(--scrollPosChevron, 0) * var(--scrollPosModifier, 1.3),
          500%
        );
        mask-position: center 0%;
      }

      &--centralise {
        mask-position: center 65%;
      }
    }

    @include above(desktop) {
      mask-size: 25vmin;

      &--active {
        mask-size: clamp(
          25vmin,
          var(--scrollPosChevron, 0) * var(--scrollPosModifier, 1.3),
          500%
        );
        mask-position: center 0%;
      }

      &--centralise {
        mask-position: center 65%;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      mask-size: 20vmin;
    }

    @media (prefers-reduced-motion: reduce) and (min-width: breakpoint-value(tablet)) {
      mask-size: 10vmin;
    }

    @media (prefers-reduced-motion: reduce) and (min-width: breakpoint-value(laptop)) {
      mask-size: 15vmin;
    }

    @media (prefers-reduced-motion: reduce) and (min-width: breakpoint-value(desktop)) {
      mask-size: 25vmin;
    }
  }
}
