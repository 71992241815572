@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.product-hero {
  position: relative;

  &__container {
    display: grid;
    grid:
      [row1-start] ". . ." 191px [row1-end]
      [row2-start] ". headline ." 1fr [row2-end]
      [row3-start] ". scroll ." 129px [row3-end]
      [row4-start] ". . ." 32px [row4-end]
      [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;

    @include above(tablet) {
      grid:
        [row1-start] ". . ." 160px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 213px [row3-end]
        [row4-start] ". . ." 24px [row4-end]
        [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;
    }

    @include above(laptop) {
      grid:
        [row1-start] ". . ." 160px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 80px [row3-end]
        [row4-start] ". . ." 24px [row4-end]
        [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . ." 150px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 168px [row3-end]
        [row4-start] ". . ." 42px [row4-end]
        [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;
    }
  }

  &__headline {
    grid-area: headline;
    text-align: center;
    color: $iceberg;

    @include above(tablet) {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
    }

    @include above(laptop) {
      max-width: 796px;
    }

    @include above(desktop) {
      max-width: 846px;
    }
  }

  &__prefix {
    font-family: $made-tommy-bold;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  }

  &__product {
    font-family: $made-tommy;
    text-transform: uppercase;

    &--Lite {
      font-size: 2rem;
      line-height: 2.5rem;
      color: $fuchsia;
    }
    &--Verify {
      font-size: 1.25rem;
      line-height: 1.5625rem;
      color: $lilac;

      @include above(desktop) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }

  &__title {
    font-family: $made-tommy-black;
    font-size: 2.125rem;
    line-height: 1.75rem;

    @include above(laptop) {
      font-family: $made-tommy-bold;
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-family: $made-tommy-black;
      font-size: 4rem;
      line-height: 3.5rem;
    }
  }

  &__sub-title {
    margin-top: 16px;
    font-size: 1rem;
    line-height: 1.25rem;

    @include above(laptop) {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 16px;
    padding: 16px 13.5px;

    @include above(tablet) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @include above(laptop) {
      padding-left: 90px;
      padding-right: 90px;
    }

    @include above(desktop) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}
