@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

@mixin triangle {
  content: "";
  position: absolute;
  border-bottom: 50vw solid $iceberg;
  border-right: 50vw solid transparent;
  z-index: 1;
}

.featured-title {
  $block: &;
  padding-top: 64px;

  @include above(tablet) {
    position: relative;
    margin-top: -50px;
    padding-top: 0;
  }

  &--using-parallax {
    @include above(laptop) {
      display: flex;
      flex-flow: column;
      width: 100%;
    }
  }

  &__background {
    position: relative;

    @include above(tablet) {
      padding-top: 120px;
      padding-bottom: 146px;
      overflow: hidden;
    }

    @include above(laptop) {
      padding-bottom: 226px;
    }

    @include above(desktop) {
      padding-top: 134px;
      padding-bottom: 242px;
    }

    #{$block}--alt & {
      @include above(laptop) {
        padding-bottom: 260px;
      }

      @include above(desktop) {
        padding-bottom: 275px;
      }
    }

    #{$block}--product & {
      @include above(desktop) {
        padding-top: 204px;
        padding-bottom: 238px;
      }
    }

    #{$block}--using-parallax & {
      @include above(laptop) {
        padding-bottom: 40px;
        overflow: visible;
        z-index: 2;
      }

      @include above(desktop) {
        padding-bottom: 70px;
      }
    }
  }

  &__container {
    @include container;
    position: relative;
    z-index: 2;

    @include above(tablet) {
      max-width: 44vw;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }

    @include above(laptop) {
      max-width: 50vw;
    }

    @include above(desktop) {
      max-width: 55vw;
    }

    @include above(maxwidth) {
      max-width: 1008px;
    }

    #{$block}--alt & {
      @include above(laptop) {
        max-width: 60vw;
      }
      @include above(desktop) {
        max-width: 70vw;
      }
      @include above(maxwidth) {
        max-width: 1008px;
      }
    }

    #{$block}--product & {
      @include above(laptop) {
        max-width: 60vw;
      }
      @include above(desktop) {
        max-width: 1064px;
      }
    }
  }

  &__title {
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    color: $topographic-square-original;

    @include above(laptop) {
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-size: 5rem;
      line-height: 4.5rem;
    }

    #{$block}--alt & {
      @include above(desktop) {
        font-size: 4rem;
        line-height: 3.5rem;
      }
    }

    #{$block}--product & {
      @include above(laptop) {
        font-size: 2.75rem;
        line-height: 2.5rem;
      }

      @include above(desktop) {
        font-family: $made-tommy-black;
        font-size: 3rem;
        line-height: 2.625rem;
      }
    }
  }

  &__desc {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    @include above(tablet) {
      margin-bottom: 0;
    }

    @include above(laptop) {
      max-width: 50vw;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  &__arrow {
    position: absolute;
    width: 100%;

    &::before,
    &::after {
      @include triangle;
      bottom: -50vw;
    }

    &::before {
      right: calc(50% + 1px);
      transform: rotate(180deg);
    }

    &::after {
      left: calc(50% + -1px);
      transform: rotate(90deg);
    }

    #{$block}--using-parallax & {
      &::before,
      &::after {
        bottom: initial;
        top: 0;
      }
    }

    @include above(tablet) {
      &::before,
      &::after {
        content: none;
      }
    }

    #{$block}--using-parallax & {
      @include above(laptop) {
        position: relative;
        min-height: 2000px;
        z-index: 2;
      }
    }
  }

  &__cross-vector {
    height: 40vw;
    width: 100vw;
    color: $cross-vector-silver;
    opacity: 0.5;
  }

  &__down-vector {
    position: absolute;
    bottom: -60px;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    z-index: 2;

    @include above(laptop) {
      height: 80px;
      width: auto;
      bottom: -120px;
    }

    @include above(desktop) {
      height: 111px;
      bottom: -181px;
    }

    #{$block}--alt & {
      @include above(desktop) {
        bottom: -151px;
      }
    }
  }

  &__triangle {
    display: block;
    position: absolute;
    bottom: -50px;
    width: 50vw;

    &--right {
      right: 0;
      transform: scaleX(-1);
    }

    @include above(laptop) {
      width: 30vw;
    }

    @include above(desktop) {
      width: 25vw;
    }

    #{$block}--alt & {
      @include above(desktop) {
        bottom: 0;
      }
    }

    #{$block}--product & {
      @include above(laptop) {
        width: 47vw;
      }
      @include above(desktop) {
        width: 30vw;
      }
    }
  }

  &__polygon {
    display: block;
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    color: white;
    z-index: 1;

    #{$block}--product & {
      @include above(laptop) {
        bottom: -97px;
        width: 221px;
        height: 133px;
      }
    }
  }

  &__diamond {
    position: absolute;
    opacity: 0.2;
    z-index: -1;

    @include above(laptop) {
      &--left,
      &--right {
        height: 200%;
        top: -100px;
      }

      &--left {
        left: -240px;
      }

      &--right {
        right: -240px;
        transform: scaleX(-1);
      }
    }
  }
}
