@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.site-footer {
  position: relative;
  width: 100vw;
  background-color: black;
  overflow: hidden;

  &__inner {
    @include container;
    position: relative;
    width: 100%;
    max-width: 144rem;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 32px;
    color: $iceberg;
    overflow: hidden;

    @include above(tablet) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @include above(laptop) {
      padding-top: 72px;
      padding-bottom: 44px;
      display: grid;
      grid-template-columns: 1fr 526px 114px;
      // column-gap: 20px;
    }

    @include above(desktop) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    height: auto;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    color: $arrow-partial;

    @include above(tablet) {
      left: 50%;
      transform: translateX(-51%);
    }

    @include above(laptop) {
      transform: translateX(-50%);
    }

    @include above(desktop) {
      height: 521px;
      min-height: auto;
    }

    @include above(maxwidth) {
      top: 50%;
      height: auto;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
