@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/z-index";

.nav-bar-hamburger {
  $block: &;
  grid-area: nav;
  position: absolute;
  width: 32px;
  height: 24px;
  padding: 0;
  border: 0;
  cursor: pointer;
  z-index: $z-nav-hamburger;

  &:hover,
  &:focus {
    background: transparent;
  }

  &--open {
    position: fixed;
    grid-area: cta;
    right: 24px;
  }

  &__bar {
    position: relative;
    width: 32px;
    height: 4px;
    background: rgba(255, 255, 255, 1);
    transition: all 0ms 300ms;

    #{$block}--dark & {
      background: rgba(0, 0, 0, 1);
    }

    &::after,
    &::before {
      position: absolute;
      left: 0;
      width: 32px;
      height: 4px;
      background: rgba(255, 255, 255, 1);

      #{$block}--dark & {
        background: rgba(0, 0, 0, 1);
      }
    }

    &::before {
      content: "";
      bottom: 10px;
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &::after {
      content: "";
      top: 10px;
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &.animate {
      width: 16px;
      height: 3px;
      background: rgba(255, 255, 255, 0);

      #{$block}--dark & {
        background: rgba(0, 0, 0, 0);
      }

      &::before,
      &::after {
        width: 16px;
        height: 3px;

        #{$block}--dark & {
          background: rgba(255, 255, 255, 1);
        }
      }

      &::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
          transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
          transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      }
    }
  }
}
