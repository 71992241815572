@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/tools/layout/container";

.subscribe-cta {
  position: relative;
  background: $slate-blue;

  &__container {
    padding: 16px;
    color: $iceberg;
  }

  &__title {
    font-family: $made-tommy-bold;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }

  &__desc,
  &__success,
  &__error {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  &__form {
    margin-top: 16px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    input {
      font-family: $made-tommy;
      padding: 14px 24px;
      color: #6c6c6c;
    }

    @include above(maxwidth) {
      max-width: 500px;
    }
  }

  &__button {
    font-family: $made-tommy-medium;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
