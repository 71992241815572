@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.contact-cta {
  position: relative;
  display: grid;
  grid:
    [row1-start] ". . ." 98px [row1-end]
    [row2-start] ". content ." auto [row2-end]
    [row3-start] ". . ." 98px [row3-end]
    / 24px 1fr 24px;

  @include above(laptop) {
    grid:
      [row1-start] ". . ." 122px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 126px [row3-end]
      / 24px 1fr 24px;
  }

  @include above(desktop) {
    grid:
      [row1-start] ". . ." 147px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 105px [row3-end]
      / 24px 1fr 24px;
  }

  &__coverImg {
    grid-area: 1 / 1 / 5 / 5;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    opacity: 0.9;

    @include above(tablet) {
      background-size: cover;
    }
  }

  &__cross-vector {
    grid-area: 1 / 1 / 5 / 5;
    height: 100%;
    width: 100%;
    color: $cross-vector-dark;
    opacity: 0.5;
    z-index: 0;
  }

  &__container {
    grid-area: content;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 1;
  }

  &__title {
    font-size: 2rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    color: $iceberg;

    @include above(laptop) {
      font-size: 2.5rem;
      line-height: 4.5rem;
    }

    &--large {
      @include above(laptop) {
        max-width: 77.7vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;
        font-size: 2.75rem;
        line-height: 2.5rem;
      }

      @include above(desktop) {
        max-width: 73.9vw;
        font-size: 3rem;
        line-height: 2.625rem;
      }

      @include above(maxwidth) {
        max-width: 1064px;
      }
    }
  }

  &__link {
    margin-top: 16px;

    @include above(tablet) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include above(laptop) {
      padding-left: 111px;
      padding-right: 111px;
    }

    @include above(laptop) {
      padding-left: 56px;
      padding-right: 56px;
    }
  }
}
