@import "../../styles/tools/media-queries/above";
@import "../../styles/tools/media-queries/below";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.nav-bar-link {
  $block: &;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;

  .nav-bar__list-container.show & {
    opacity: 1;
  }

  &#{$block}--cta &__url {
    padding: 12px 16px;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: capitalize;
    border: 1px solid $iceberg;
  }

  &#{$block}--has-children {
    @include above(laptop) {
      &:hover,
      &:focus {
        #{$block}__sub-container {
          opacity: 1;
          transition-delay: 0s;
          pointer-events: all;
        }
      }
    }
  }

  @include above(laptop) {
    margin-top: 0;
    opacity: 1;

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  &__dummy {
    opacity: 0;
    padding: 0 1px;
    font-size: 1.5rem;
    line-height: 1.875rem;

    @include above(laptop) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  #{$block}__url {
    font-family: $made-tommy-light;
    font-size: 1.5rem;
    line-height: 1.875rem;
    text-transform: uppercase;
    transition: color 0.3s ease-in;

    &--active {
      font-family: $made-tommy-medium;
      font-weight: 500;
      padding: 0;
      border: 0;
    }

    &--sub {
      @include below(laptop) {
        font-size: 1.125rem;
        line-height: 1.375rem;
        opacity: 0.8;
      }
    }

    @include above(laptop) {
      position: absolute;
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: none;

      &:hover,
      &:focus {
        text-decoration: none;
        font-family: $made-tommy-medium;
      }

      &--has-children {
        z-index: 1;
      }

      &--sub {
        display: block;
        padding-bottom: 8px;
        top: 0;
        width: 100%;
        border-bottom: 1px solid;
      }

      &--dark {
        color: black;

        &:hover,
        &:focus {
          color: $cyan;
        }
      }
    }
  }

  &__chevron-container {
    display: flex;
    width: 100%;
    height: 13px;
    justify-content: center;
    transition: opacity 1s linear 0.25s, max-height 1s linear 0.25s;

    &--mobile-hide {
      @include below(laptop) {
        height: 0;
        opacity: 0;
      }
    }

    &--invert {
      margin-top: 32px;
      margin-bottom: 10px;
    }

    &:hover,
    &:focus {
      cursor: pointer;
    }

    @include above(laptop) {
      height: 9px;
    }
  }

  &__chevron {
    padding-top: 5px;
    height: 13px;
    width: 100%;
    color: $iceberg;
    z-index: 1;

    &--invert {
      transform: scaleY(-1);
    }

    @include above(laptop) {
      height: 9px;

      &--dark {
        color: black;
      }
    }
  }

  &__sub-link {
    position: relative;

    &:not(:first-child) {
      margin-top: 28px;

      @include above(laptop) {
        margin-top: 12px;
      }
    }
  }

  &__sub-container {
    max-height: 0;
    opacity: 0;
    transition: opacity 1s linear 0.25s, background-color 1s linear 0.25s,
      border-color 1s linear 0.25s, max-height 1s linear 0.25s;

    &--show {
      max-height: 300px;
      opacity: 1;
      transition-delay: 0s;
      pointer-events: all;

      @include above(laptop) {
        max-height: none;
        overflow: visible;
      }
    }

    @include above(laptop) {
      position: absolute;
      top: 30px;
      left: -5px;
      width: calc(100% + 10px);
      max-height: none;
      padding-top: 20px;
      pointer-events: none;
      border-radius: 5px;
      border: 5px solid transparent;
      border-top: none;

      &--dark {
        background: white;
        border-color: white;
      }

      &:hover,
      &:focus {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__sub-list {
    max-height: 250px;
    padding-top: 32px;
    text-align: center;
    list-style: none;
    overflow: scroll;

    @include above(laptop) {
      max-height: none;
      padding-top: 0;
      text-align: left;
      overflow: visible;
    }
  }
}
