@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/tools/layout/container";

.table-of-contents {
  $block: &;
  margin-top: 48px;
  margin-bottom: 40px;

  @include above(laptop) {
    margin-top: 0;
    margin-bottom: 28px;
  }

  @include above(desktop) {
    position: sticky;
    top: 48px;
  }

  &--no-content {
    margin: 0;
  }

  &__container {
    @include container;

    @include above(laptop) {
      padding: 0;
    }
  }

  &__title {
    font-family: $made-tommy-medium;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    color: #000000;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__chevron {
    &--active {
      transform: rotate(-180deg);
    }
  }

  &__nav {
    overflow: auto;
  }

  &__list {
    margin-top: 24px;
    list-style: none;
  }

  &__item {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  #{$block}__link {
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
    color: black;

    &:hover,
    &:focus {
      color: $cyan;
    }

    // TODO update active logic
    // &--active {
    //   color: $lilac;
    // }
  }
}
