@import "../../styles/tools/media-queries/above";
@import "../../styles/tools/media-queries/between";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.home-hero {
  position: relative;

  &__container {
    display: grid;
    grid:
      [row1-start] ". . ." 191px [row1-end]
      [row2-start] ". headline ." 1fr [row2-end]
      [row3-start] ". scroll ." 129px [row3-end]
      [row4-start] ". . ." 32px [row4-end]
      [row5-start] "mask mask mask" 50px [row5-end]/24px 1fr 24px;

    @include above(laptop) {
      grid:
        [row1-start] ". . ." 181px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 129px [row3-end]
        [row4-start] ". . ." 22px [row4-end]
        [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . ." 191px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 129px [row3-end]
        [row4-start] ". . ." 43px [row4-end]
        [row5-start] "mask mask mask" 50px [row5-end]/24px auto 24px;
    }
  }

  &__headline {
    grid-area: headline;
    text-align: center;
    color: $iceberg;

    @include above(laptop) {
      max-width: 61.9vw;
      margin-left: auto;
      margin-right: auto;
    }

    @include above(desktop) {
      max-width: 48vw;
    }

    @include above(maxwidth) {
      max-width: 696px;
    }
  }

  &__title {
    font-family: $made-tommy-bold;

    @include between(tablet, laptop) {
      max-width: 55vw;
      margin-left: auto;
      margin-right: auto;
    }

    @include above(desktop) {
      font-size: 5rem;
      line-height: 4.5rem;
    }
  }

  &__sub-title {
    font-family: $made-tommy-light;
    margin-top: 20px;
    font-size: 1.25rem;
    line-height: 1.5625rem;

    @include above(tablet) {
      margin-top: 24px;
    }

    @include above(laptop) {
      margin-top: 20px;
      font-size: 1.125rem;
      line-height: 1.375rem;
    }

    @include above(desktop) {
      margin-top: 24px;
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 32px;

    @include above(tablet) {
      margin-top: 24px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include above(laptop) {
      margin-top: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @include above(desktop) {
      margin-top: 32px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
