@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/tools/layout/container";

.related-posts {
  $block: &;
  margin-top: 88px;
  padding-bottom: 44px;
  background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);

  @include above(tablet) {
    padding-bottom: 48px;
  }

  @include above(laptop) {
    margin-top: 48px;
  }

  @include above(laptop) {
    margin-top: 88px;
    padding-bottom: 64px;
  }

  &#{$block}--no-posts {
    margin: 0;
    padding: 0;
  }

  &__container {
    @include container;
    margin-top: 32px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    gap: 32px;

    @include above(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    @include above(laptop) {
      margin-top: 48px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include above(desktop) {
      margin-top: 40px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
    }
  }
}
