@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.title-link {
  $block: &;
  display: block;
  overflow: hidden;

  @include above(tablet) {
    flex-basis: 50%;

    &:last-child:nth-child(odd) {
      flex-basis: 100%;
    }
  }

  @include above(maxwidth) {
    &:nth-child(odd) {
      #{$block}__wrap {
        grid:
          [row1-start] ". . ." 208px [row1-end]
          [row2-start] ". content ." auto [row2-end]
          [row3-start] ". . ." 162px [row3-end]/1fr auto 100px;
      }
    }

    &:nth-child(even) {
      #{$block}__wrap {
        grid:
          [row1-start] ". . ." 208px [row1-end]
          [row2-start] ". content ." auto [row2-end]
          [row3-start] ". . ." 162px [row3-end]/100px auto 1fr;
      }
    }

    &:last-child:nth-child(odd) {
      #{$block}__wrap {
        grid:
          [row1-start] ". . ." 208px [row1-end]
          [row2-start] ". content ." auto [row2-end]
          [row3-start] ". . ." 162px [row3-end]
          / 24px 1fr 24px;
      }
    }
  }

  &__wrap {
    display: grid;
    grid:
      [row1-start] ". . ." 208px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 162px [row3-end]
      / 24px 1fr 24px;
  }

  &__coverImg {
    grid-area: 1 / 1 / 5 / 5;
    opacity: 0.9;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__cross-vector {
    grid-area: 1 / 1 / 5 / 5;
    height: 100%;
    width: 100%;
    color: $cross-vector-dark;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    opacity: 0.8;
    z-index: 0;
  }

  &__container {
    grid-area: content;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 1;
    color: $iceberg;
    text-transform: uppercase;
    text-align: center;
  }

  &__title {
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 4rem;
    line-height: 3.75rem;

    @include above(tablet) {
      font-size: 3.5rem;
      line-height: 3rem;
    }

    @include above(laptop) {
      font-size: 4rem;
      line-height: 3.5rem;
    }

    @include above(desktop) {
      line-height: 5.125rem;
    }
  }

  &__subtitle {
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
}
