@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.job-list {
  margin-top: 32px;
  margin-bottom: 24px;

  @include above(tablet) {
    margin-bottom: 70px;
  }

  @include above(laptop) {
    margin-top: 64px;
    margin-bottom: 40px;
  }

  @include above(desktop) {
    padding-bottom: 84px;
  }

  &__container {
    @include container;
    display: flex;
    flex-flow: column;
    gap: 24px;

    @include above(laptop) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }

    @include above(desktop) {
      gap: 100px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
