@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.category-list {
  position: relative;
  margin-top: 95px;
  padding-bottom: 51px;

  @include above(tablet) {
    margin-top: 115px;
    padding-bottom: 48px;
  }

  @include above(laptop) {
    margin-top: 154px;
    padding-bottom: 64px;
  }

  @include above(desktop) {
    margin-top: 342px;
    padding-bottom: 80px;
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 32px;
    z-index: 1;

    @include above(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 16px;
    }

    @include above(laptop) {
      padding: 0;
      row-gap: 16px;
      max-width: 796px;
    }

    @include above(desktop) {
      max-width: 844px;
      column-gap: 32px;
      row-gap: 32px;
    }
  }

  &__polygon {
    display: block;
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    color: #dadada;
    z-index: 2;
  }
}
