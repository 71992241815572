@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.culture-feature {
  $block: &;
  opacity: 0;
  visibility: hidden;
  transition: none;

  &__container {
    position: relative;
    color: $iceberg;
    overflow: hidden;
  }

  &__image,
  &__image-placeholder {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 96px;
    width: 96px;
  }

  &__image-placeholder {
    background-color: #d9d9d9;
  }

  &__title {
    margin-top: 24px;
    font-size: 1.125rem;
    line-height: 1.125rem;
    text-align: center;
    color: $indigo;
    font-family: $made-tommy;
    font-weight: 400;

    @include above(laptop) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
}
