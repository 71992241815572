@import "../../styles/tools/media-queries/above";

.featured-title-diamond {
  $block: &;

  &__vector {
    position: absolute;
    opacity: 0.2;
    z-index: -1;

    @include above(laptop) {
      &--left,
      &--right {
        height: 200%;
        top: -100px;
      }

      &--left {
        left: -240px;
      }

      &--right {
        right: -240px;
        transform: scaleX(-1);
      }
    }

    #{$block}--using-parallax & {
      @include above(laptop) {
        top: 100px;
      }
      @include above(desktop) {
        top: 150px;
      }
    }
  }
}
