@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.explore-products {
  $block: &;
  position: relative;
  padding-top: 40px;
  padding-bottom: 64px;
  background-color: $indigo;

  @include above(laptop) {
    background-color: black;
    padding-bottom: 32px;
  }

  @include above(desktop) {
    padding-bottom: 70px;
  }

  &#{$block}--light {
    background-color: inherit;

    @include above(tablet) {
      margin-top: 48px;
      padding-top: 0;
      padding-bottom: 58px;
    }

    @include above(laptop) {
      margin-top: 141px;
      padding-bottom: 71px;
    }

    @include above(desktop) {
      margin-top: 137px;
      padding-bottom: 53px;
    }
  }

  &__title {
    position: relative;
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: $indigo;

    #{$block}--light & {
      color: $right-arrow-solid;
    }
  }

  &__arrow {
    position: absolute;
    top: -95px;
    height: 110%;
    color: $right-arrow-solid;

    &--left {
      left: 0;
    }
    &--right {
      right: 0;
      transform: scaleX(-1);
    }

    @include above(desktop) {
      top: -142px;
      height: 120%;
    }
  }

  &__container {
    @include container;
    position: relative;

    @include above(laptop) {
      padding-left: 56px;
      padding-right: 56px;
    }
  }

  &__products {
    margin-top: 46px;

    @include above(laptop) {
      display: flex;
      flex-flow: row;
      margin-top: 32px;
    }

    @include above(desktop) {
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
    }

    #{$block}--light & {
      @include above(tablet) {
        margin-top: 40px;
      }
      @include above(desktop) {
        margin-top: 15px;
      }
    }
  }
}
