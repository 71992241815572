@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.feature {
  $block: &;
  margin-top: 64px;
  opacity: 0;
  visibility: hidden;
  transition: none;

  @include above(tablet) {
    margin-top: 0;
  }

  &__container {
    position: relative;
    padding: 0 20px;
    color: $iceberg;
    overflow: hidden;

    @include above(tablet) {
      padding: 0;
    }
  }

  &__image,
  &__image-placeholder {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 64px;
    width: 64px;
  }

  &__image-placeholder {
    background-color: #d9d9d9;
  }

  &__title {
    margin-top: 16px;
    font-family: $made-tommy-black;
    font-size: 1.5rem;
    line-height: 1.875rem;
    text-align: center;
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: center;
  }
}
