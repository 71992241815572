@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.hero {
  position: relative;

  &__container {
    display: grid;
    grid:
      [row1-start] ". . ." 191px [row1-end]
      [row2-start] ". headline ." 1fr [row2-end]
      [row3-start] ". scroll ." 129px [row3-end]
      [row4-start] "mask mask mask" 50px [row4-end]
      / 24px auto 24px;

    @include above(tablet) {
      grid:
        [row1-start] ". . ." 278px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 62px [row3-end]
        [row4-start] "mask mask mask" 50px [row4-end]/24px auto 24px;
    }

    @include above(laptop) {
      grid:
        [row1-start] ". . ." 192px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 64px [row3-end]
        [row4-start] "mask mask mask" 50px [row4-end]/24px auto 24px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . ." 222px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". scroll ." 114px [row3-end]
        [row4-start] "mask mask mask" 50px [row4-end]/24px auto 24px;
    }
  }

  &__headline {
    grid-area: headline;
    text-align: center;
    color: $iceberg;
  }

  &__title {
    @include above(laptop) {
      font-size: 3.125rem;
      line-height: 4rem;
    }
  }
}
