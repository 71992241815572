@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.platforms {
  padding-top: 117px;
  padding-bottom: 117px;
  background-color: $arrow-partial;

  @include above(tablet) {
    order: 1;
    flex-basis: 50%;
  }

  @include above(desktop) {
    padding-top: 228px;
    padding-bottom: 228px;
  }

  &__container {
    @include container;
    color: $iceberg;

    @include above(tablet) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include above(laptop) {
      padding-left: $laptop-gutter;
      padding-right: $laptop-gutter;
    }

    @include above(desktop) {
      padding-left: 120px;
      padding-right: 120px;
    }

    @include above(maxwidth) {
      max-width: 800px;
    }
  }

  &__title {
    font-family: $made-tommy-black;
    font-size: 1.75rem;
    line-height: 2.1875rem;
    text-align: center;
  }

  &__list {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include above(laptop) {
      justify-content: space-evenly;
    }
  }
}
