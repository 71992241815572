@import "../functions/breakpoint-value";

@mixin between($key-from, $key-to) {
  $min-width: breakpoint-value($key-from);
  $max-width: breakpoint-value($key-to) - 1px;

  @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;
  }
}
