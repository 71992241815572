@import "../../styles/tools/media-queries/above";

.featured-title-triangle {
  $block: &;

  &__vector {
    display: block;
    position: absolute;
    bottom: -50px;
    width: 50vw;

    &--right {
      right: 0;
      transform: scaleX(-1);
    }

    @include above(laptop) {
      width: 30vw;
    }

    @include above(desktop) {
      width: 25vw;
    }

    #{$block}--alt & {
      @include above(desktop) {
        bottom: 0;
      }
    }

    #{$block}--product & {
      @include above(laptop) {
        width: 47vw;
      }
      @include above(desktop) {
        width: 30vw;
      }
    }

    #{$block}--using-parallax & {
      @include above(laptop) {
        top: 100px;
      }
      @include above(desktop) {
        top: 150px;
      }
    }
  }
}
