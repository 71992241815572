@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: $indigo;
  z-index: 50;

  &__container {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__gif {
    height: 200px;
    width: 200px;
  }
}

.loading {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}
