@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.insights {
  position: relative;
  padding-top: 64px;
  background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);
  overflow: hidden;

  @include above(laptop) {
    padding-top: 80px;
  }

  @include above(desktop) {
    padding-top: 67px;
  }

  &__container {
    @include container;
    position: relative;
    text-align: center;

    @include above(desktop) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &__sub-container {
    @include above(desktop) {
      margin-top: 80px;
      display: flex;
      gap: 24px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__sub-info {
    @include above(desktop) {
      width: 344px;
      padding-top: 48px;
      margin-bottom: 136px;
    }
  }

  &__up-arrow {
    position: absolute;
    bottom: 0;
    color: $watermark-negative;
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    color: $watermark-negative;

    @include above(desktop) {
      width: 100%;
    }
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1.25rem;
    line-height: 1.5625rem;

    @include above(laptop) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }

    @include above(desktop) {
      max-width: 1030px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__subtitle {
    margin-top: 56px;
    font-family: $made-tommy-bold;
    font-size: 1.5rem;
    line-height: 1.25rem;
    text-transform: uppercase;

    @include above(tablet) {
      margin-top: 64px;
    }

    @include above(laptop) {
      font-size: 2rem;
      line-height: 2rem;
    }

    @include above(desktop) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__secondary-desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;

    @include above(desktop) {
      text-align: left;
    }
  }

  &__image {
    display: block;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    max-height: 138px;

    @include above(tablet) {
      max-height: 218px;
    }

    @include above(laptop) {
      max-height: 298px;
    }

    @include above(desktop) {
      margin-top: 0;
      max-height: 384px;
    }
  }
}
