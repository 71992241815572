@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.statistic-list {
  position: relative;
  padding-top: 43px;
  padding-bottom: 118px;
  background-color: $arrow-partial;
  overflow: hidden;

  @include above(tablet) {
    padding-bottom: 104px;
  }

  @include above(laptop) {
    padding-top: 30px;
    padding-bottom: 122px;
  }

  @include above(desktop) {
    padding-top: 76px;
    padding-bottom: 176px;
  }

  &__cross-vector {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    color: black;
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: $iceberg;

    @include above(tablet) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 32px;
      align-items: flex-start;
      padding-left: 24px;
      padding-right: 24px;
    }

    @include above(laptop) {
      padding-left: 130px;
      padding-right: 130px;
    }

    @include above(desktop) {
      padding-left: 80px;
      padding-right: 80px;
      grid-template-columns: repeat(5, auto);
      grid-column-gap: 15px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
