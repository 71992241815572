@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.product {
  $block: &;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 32px;
    padding-top: 36px;
    border-top: 1px solid rgba($iceberg, 0.4);
  }

  @include above(laptop) {
    padding-top: 58px;
    padding-bottom: 58px;
    flex-basis: 50%;
    align-items: flex-start;

    &:first-child {
      padding-right: 68px;
    }

    &:not(:first-child) {
      padding-top: 58px;
      margin-top: 0;
      padding-left: 68px;
      border-left: 1px solid $iceberg;
      border-top: none;
    }
  }

  @include above(desktop) {
    padding-top: 64px;
    padding-bottom: 70px;

    &:first-child {
      padding-right: 50px;
    }

    &:not(:first-child) {
      padding-left: 50px;
    }
  }

  &--dark {
    &:not(:first-child) {
      border-color: rgba(0, 0, 0, 0.4);

      @include above(tablet) {
        position: relative;
        border-top: 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          width: 327px;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      @include above(laptop) {
        &::before {
          content: none;
        }
      }
    }

    @include above(laptop) {
      padding-bottom: 45px;

      &:first-child {
        padding-top: 82px;
      }
      &:not(:first-child) {
        padding-top: 82px;
      }
    }

    @include above(desktop) {
      &:first-child {
        padding-top: 102px;
      }
      &:not(:first-child) {
        padding-top: 102px;
      }
    }
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include above(laptop) {
      align-items: flex-start;
    }
  }

  &__logo {
    display: block;
    height: 36px;
  }

  &__desc {
    margin-top: 32px;
    font-size: 1.5rem;
    line-height: 1.875rem;
    text-transform: uppercase;
    text-align: center;
    color: $iceberg;

    #{$block}--dark & {
      color: black;

      @include above(tablet) {
        margin-top: 32px;
      }

      @include above(laptop) {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      @include above(desktop) {
        margin-top: 29px;
      }
    }

    @include above(tablet) {
      margin-top: 38px;
    }

    @include above(laptop) {
      margin-top: 32px;
      text-align: left;
    }
  }

  &__link {
    margin-top: 24px;

    #{$block}--dark & {
      @include above(laptop) {
        margin-top: 40px;
        padding-left: 82px;
        padding-right: 82px;
      }

      @include above(desktop) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    @include above(tablet) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include above(laptop) {
      margin-top: 32px;
    }

    @include above(desktop) {
      margin-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
