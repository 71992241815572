@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.featured-pages {
  padding-top: 36px; // Effectively 23px, but due to transform of box...
  padding-bottom: 59px; // Effectively 36px, but due to transform of box...
  overflow: hidden;

  @include above(tablet) {
    overflow: visible;
  }

  @include above(laptop) {
    margin-top: 72px;
  }

  @include above(desktop) {
    margin-top: 60px;
  }

  &__container {
    @include container;
    position: relative;
  }

  &__box-list {
    gap: 8px;
    display: grid;
    grid:
      [row1-start] ". ." 1fr [row1-end]
      [row2-start] ". ." 1fr [row2-end]
      / 1fr 1fr;
    justify-items: center;

    @include above(tablet) {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
    }

    @include above(maxwidth) {
      max-width: 1228px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 112px;
    transform: rotate(-45deg);
    background: $silver-grey;
    transition: none;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &--has-image {
      background: none;
    }

    &:nth-child(n + 3) {
      margin-top: 27px;
    }

    @include above(tablet) {
      &:nth-child(n + 3) {
        margin-top: 0;
      }
    }

    @include above(laptop) {
      width: 140px;
      height: 140px;
      transform: scale(1) rotate(-45deg);
      box-shadow: 0 0 0 0 rgba(black, 0.5);

      &:hover,
      &:focus {
        animation: pulse 2s infinite;
      }
    }

    @include above(desktop) {
      width: 200px;
      height: 200px;
    }
  }

  &__image {
    position: absolute;
    width: 140%;
    height: 140%;
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(45deg);
  }

  &__link {
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    text-transform: uppercase;
    color: $iceberg;
    transform: rotate(45deg);

    @include above(laptop) {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include above(desktop) {
      font-size: 2rem;
      line-height: 2.5625rem;
    }
  }

  &__cross-vector {
    position: absolute;
    opacity: 0.2;
    z-index: -1;

    &--left,
    &--right {
      top: -200%;
    }

    &--left {
      left: -30%;
      transform: translate(-50%);
    }

    &--right {
      right: -30%;
      transform: scaleX(-1) translate(-50%);
    }

    @include above(tablet) {
      &--left,
      &--right {
        top: -350%;
        height: 800%;
      }

      &--left {
        left: -10%;
        transform: none;
      }

      &--right {
        right: -10%;
        transform: scaleX(-1);
      }
    }

    @include above(laptop) {
      &--left {
        left: -7%;
      }

      &--right {
        right: -7%;
      }
    }

    @include above(desktop) {
      &--left {
        left: -10%;
      }

      &--right {
        right: -10%;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(-45deg);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1) rotate(-45deg);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95) rotate(-45deg);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
