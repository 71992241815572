@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/tools/layout/container";

.job-post {
  &__container {
    display: flex;
    flex-flow: column;

    @include above(laptop) {
      display: grid;
      grid:
        [row1-start] ". . . ." 48px [row1-end]
        [row2-start] ". content cta ." 1fr [row2-end]
        / 32px 1fr auto 32px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . . ." 48px [row1-end]
        [row2-start] ". content cta ." 1fr [row2-end]
        / 80px 1fr auto 80px;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    @include above(laptop) {
      grid-area: content;
    }
  }

  &__cta {
    @include container;
    margin-top: 40px;
    margin-bottom: 40px;

    @include above(laptop) {
      grid-area: cta;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 56px;
      width: 216px;
    }

    @include above(desktop) {
      width: 261px;
      margin-left: 64px;
    }
  }
}
