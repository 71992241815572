*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  // -webkit-transition: all 0.3s ease-in-out;
  // transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
}

a {
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  // Backup link styling to prevent iPhone auto styling
  &[href^="tel"] {
    color: inherit;
  }

  &:link,
  &:visited {
    text-decoration: none;
    color: $iceberg;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $cyan;
  }
}

button,
.button {
  @include button;

  &--solid {
    @include button-solid;
  }

  &--dark {
    @include button-dark;
  }

  &--minimal {
    @include button-minimal;
  }
}

// CSS Transition
.fade {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  &-enter-done {
    opacity: 1;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  &-exit-done {
    opacity: 0;
  }
}
