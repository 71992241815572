@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.location {
  opacity: 0;
  visibility: hidden;
  transition: none;

  &:not(:first-child) {
    margin-top: 28px;
  }

  @include above(tablet) {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  @include above(laptop) {
    display: flex;
    gap: 28px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__name {
    margin-top: 28px;
    font-family: $made-tommy-medium;
    font-size: 1.25rem;
    line-height: 1.5625rem;

    @include above(tablet) {
      margin-top: 20px;
    }
    @include above(laptop) {
      margin-top: 0px;
    }
  }

  &__text {
    margin-top: 8px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
