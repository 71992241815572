@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.statistic {
  transition: none;

  &:not(:first-child) {
    margin-top: 32px;

    @include above(tablet) {
      margin-top: 0;
    }
  }

  @include above(tablet) {
    grid-column: span 2;

    &:nth-child(5n + 4),
    &:nth-child(5n) {
      grid-column: span 3;
    }
  }

  @include above(laptop) {
    &:nth-child(5n + 4) {
      margin-left: 70px;
    }
    &:nth-child(5n) {
      margin-right: 70px;
    }
  }

  @include above(desktop) {
    grid-column: span 1;

    &:nth-child(5n + 4),
    &:nth-child(5n) {
      margin-left: 0;
      margin-right: 0;
      grid-column: span 1;
    }
  }

  &__container {
    position: relative;
    padding: 0 20px;
    color: $iceberg;

    @include above(tablet) {
      padding: 0;
    }
  }

  &__value-container {
    position: relative;
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__number,
  &__prefix,
  &__mid,
  &__suffix,
  &__dummy {
    font-family: $made-tommy-black;
    font-size: 3.5rem;
    line-height: 4.4375rem;
    text-align: center;
    color: $cyan;
  }

  // Used for sizing, as value is absolute
  &__dummy {
    opacity: 0;
    visibility: hidden;
  }

  &__subtitle {
    font-family: $made-tommy-bold;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
  }

  &__desc {
    margin-top: 8px;
    font-family: $made-tommy-light;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: center;
  }
}
