@import "../../styles/core/scss-variables";

.topography-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: black;

  &__topography {
    position: absolute;
    top: 0;
    overflow: hidden;
    color: $topographic-vector-original;
    opacity: 0.5;
    min-width: 100%;
    min-height: 100%;
  }
}
