@import "../fonts/made_tommy/style.scss";

$made-tommy-black: "made_tommyblack", sans-serif;
$made-tommy-bold: "made_tommybold", sans-serif;
$made-tommy-medium: "made_tommymedium", sans-serif;
$made-tommy: "made_tommyregular", sans-serif;
$made-tommy-light: "made_tommylight", sans-serif;

// Made Tommy Black => 800
// Made Tommy Bold => 700
// Made Tommy Medium => 500
// Made Tommy Regular => 400
// Made Tommy Light => 300
