@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.diamond-image {
  margin-top: 64px;
  margin-bottom: 64px;

  @include above(tablet) {
    margin-top: 11px;
    margin-bottom: 48px;
  }

  @include above(laptop) {
    margin-top: 59px;
  }

  @include above(desktop) {
    margin-top: 20px;
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 280px;
    height: 280px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background-size: cover;
    background-position: center center;

    @include above(laptop) {
      width: 360px;
      height: 360px;
    }

    @include above(laptop) {
      width: 414px;
      height: 414px;
    }
  }
}
