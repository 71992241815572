@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.team-profile {
  $block: &;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  min-height: 307px;
  min-width: 200px;

  &__img-container {
    height: 120px;
    background-color: #fff;
  }

  &__img {
    display: block;
    max-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 24px;
    background-color: white;
    color: black;
  }

  &__name {
    font-family: $made-tommy;
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }

  &__text {
    margin-top: 12px;
    margin-bottom: 16px;
    font-family: $made-tommy-light;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  &__link {
    margin-top: auto;
    line-height: 1rem;
  }

  &__icon {
    height: 24px;
    width: 24px;
    color: $linkedin;
  }
}
