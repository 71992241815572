@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.content-over-image {
  display: grid;
  grid:
    [row1-start] ". . ." 80px [row1-end]
    [row2-start] ". content ." auto [row2-end]
    [row3-start] ". . ." 80px [row3-end]
    / 24px 1fr 24px;

  @include above(tablet) {
    order: 0;
    flex-basis: 50%;
    grid:
      [row1-start] ". . ." 95px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 90px [row3-end]
      / 48px 1fr 48px;
  }

  @include above(laptop) {
    grid:
      [row1-start] ". . ." 140px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 140px [row3-end]
      / 32px 1fr 32px;
  }

  @include above(desktop) {
    grid:
      [row1-start] ". . ." 241px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 241px [row3-end]
      / 120px 1fr 120px;
  }

  @include above(maxwidth) {
    grid:
      [row1-start] ". . ." 241px [row1-end]
      [row2-start] ". content ." auto [row2-end]
      [row3-start] ". . ." 241px [row3-end]/1fr 800px 1fr;
  }

  &__coverImg {
    grid-area: 1 / 1 / 5 / 5;
    opacity: 0.9;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__cross-vector {
    grid-area: 1 / 1 / 5 / 5;
    height: 100%;
    width: 100%;
    color: $cross-vector-dark;
    background: linear-gradient(
      0deg,
      rgba(23, 15, 36, 0.6),
      rgba(23, 15, 36, 0.6)
    );
    opacity: 0.8;
    z-index: 0;
  }

  &__container {
    grid-area: content;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 1;
    color: $iceberg;

    @include above(tablet) {
      align-items: flex-start;
    }
  }

  &__title {
    font-family: $made-tommy-black;
    font-size: 1.75rem;
    line-height: 2.1875rem;
  }

  &__desc {
    margin-top: 20px;
    font-family: $made-tommy-light;
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}
