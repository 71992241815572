@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.our-locations {
  position: relative;

  @include above(desktop) {
    display: flex;
  }

  &__image {
    display: block;
    width: 100%;

    @include above(desktop) {
      width: 50%;
    }
  }

  &__container {
    @include container;
    margin-top: 48px;
    margin-bottom: 71px;

    @include above(tablet) {
      margin-top: 56px;
      margin-bottom: 40px;
    }

    @include above(laptop) {
      margin-top: 40px;
      margin-bottom: 53px;
    }

    @include above(desktop) {
      margin-top: 56px;
      margin-bottom: 61px;
      padding-left: 64px;
      padding-right: 81px;
      width: 50%;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;

    @include above(maxwidth) {
      max-width: 500px;
    }
  }

  &__list {
    margin-top: 40px;

    @include above(tablet) {
      margin-top: 56px;
      display: flex;
      gap: 28px;
    }
    @include above(laptop) {
      margin-top: 40px;
    }
    @include above(desktop) {
      margin-top: 32px;
      flex-flow: column;
    }
  }
}
