@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.job-thumbnail {
  $block: &;
  position: relative;
  background-color: $cross-vector-light;
  opacity: 0;
  visibility: hidden;
  transition: none;

  &__cover-img {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 100%;
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__tag-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    padding: 26px 24px 11px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  &__container {
    padding: 24px;
    overflow: hidden;
  }

  &__title {
    font-family: $made-tommy-bold;
    font-size: 1.5rem;
    line-height: 1.6875rem;
    text-transform: capitalize;
    color: black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__desc {
    margin-top: 4px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $silver-grey;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
