@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.info-panel-group {
  margin-top: 34px;
  margin-bottom: 24px;

  @include above(tablet) {
    margin-top: 95px;
    margin-bottom: 64px;
  }

  @include above(laptop) {
    margin-top: 124px;
  }

  @include above(desktop) {
    margin-top: 78px;
  }

  &__container {
    @include container;

    @include above(tablet) {
      display: flex;
      flex-flow: column;
      gap: 20px;
      padding-left: 86px;
      padding-right: 86px;
    }

    @include above(laptop) {
      gap: 32px;
      max-width: 796px;
      padding-left: 0;
      padding-right: 0;
    }

    @include above(desktop) {
      gap: 56px;
      max-width: 1064px;
    }
  }
}
