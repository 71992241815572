@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.title-with-cta {
  $block: &;
  position: relative;
  margin-top: 64px;
  padding-bottom: 36px;

  @include above(tablet) {
    margin-top: 70px;
  }

  @include above(desktop) {
    margin-top: 150px;
  }

  &#{$block}--hero {
    margin-top: 0;
    padding-top: 180px;

    @include above(tablet) {
      margin-top: 0;
      padding-top: 160px;
    }

    @include above(laptop) {
      padding-top: 212px;
    }

    @include above(desktop) {
      padding-top: 259px;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }

  &#{$block}--watermark {
    padding-bottom: 139px;
    overflow: hidden;
    background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);

    @include above(tablet) {
      padding-bottom: 87px;
    }

    @include above(laptop) {
      overflow: visible;
      padding-top: 232px;
      padding-bottom: 219px;
    }

    @include above(desktop) {
      margin-bottom: 0;
      padding-top: 313px;
      padding-bottom: 232px;
    }
  }

  &__watermark-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    color: $watermark-negative;

    @include above(tablet) {
      height: 100%;
    }

    @include above(laptop) {
      height: auto;
      width: 100%;
    }
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 24px;
    align-items: center;

    @include above(tablet) {
      max-width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }

    @include above(laptop) {
      max-width: 65vw;
    }

    @include above(maxwidth) {
      max-width: 936px;
    }

    #{$block}--hero & {
      @include above(laptop) {
        max-width: 70vw;
      }

      @include above(maxwidth) {
        max-width: 1008px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__title {
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.01em;

    @include above(laptop) {
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-size: 4.5rem;
      line-height: 4rem;
    }

    &--hero {
      font-family: $made-tommy-black;
      font-weight: 900;

      @include above(desktop) {
        font-size: 4.5rem;
        line-height: 4rem;
      }

      #{$block}--watermark & {
        @include above(desktop) {
          font-size: 4rem;
          line-height: 5.125rem;
        }
      }
    }
  }

  &__desc {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    @include above(laptop) {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }

    @include above(desktop) {
      max-width: 40vw;
    }
  }

  &__link {
    font-family: $made-tommy-medium;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: capitalize;

    @include above(tablet) {
      padding-left: 71px;
      padding-right: 71px;
    }

    @include above(laptop) {
      padding-left: 110px;
      padding-right: 110px;
    }

    @include above(desktop) {
      padding-left: 40px;
      padding-right: 40px;
    }

    #{$block}--hero & {
      padding: 16px 13.5px;

      @include above(tablet) {
        padding-left: 50px;
        padding-right: 50px;
      }

      @include above(desktop) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    #{$block}--watermark & {
      @include above(laptop) {
        padding-left: 90px;
        padding-right: 90px;
      }
      @include above(desktop) {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }

  &__diamond {
    @include above(laptop) {
      position: absolute;
      left: 50%;
      bottom: -180px;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}
