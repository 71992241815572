@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.title-with-rte {
  $block: &;
  padding-top: 40px;
  margin-bottom: 32px;

  @include above(tablet) {
    margin-bottom: 48px;
  }

  @include above(laptop) {
    margin-top: 30px;
    margin-bottom: 64px;
  }

  @include above(desktop) {
    margin-top: 40px;
  }

  &__title {
    @include container;
  }

  &__container {
    margin-top: 24px;
    text-align: center;

    @include above(tablet) {
      margin-top: 64px;
    }

    @include above(laptop) {
      margin-top: 40px;
    }

    @include above(desktop) {
      margin-top: 24px;
    }
  }
}
