@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.features-list {
  position: relative;
  background-color: #071017;
  overflow: hidden;

  &__background {
    position: relative;
    padding-top: 56px;
    padding-bottom: 54px;
    overflow: hidden;

    @include above(tablet) {
      padding-top: 90px;
      padding-bottom: 64px;
    }

    @include above(laptop) {
      padding-top: 120px;
    }

    @include above(desktop) {
      padding-top: 160px;
      padding-bottom: 118px;
    }
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: $iceberg;

    @include above(tablet) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include above(laptop) {
      padding-left: 56px;
      padding-right: 56px;
    }

    @include above(desktop) {
      flex-flow: row;
      align-items: flex-start;
      padding-left: 80px;
      padding-right: 80px;
      gap: 103px;

      &--max-width {
        max-width: 1064px;
        padding: 0;
      }
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      padding: 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;

    @include above(desktop) {
      align-items: flex-start;
    }
  }

  &__down-arrow,
  &__up-arrow,
  &__watermark {
    position: absolute;
    color: $arrow-partial;
  }

  &__down-arrow,
  &__watermark {
    top: 0;
  }

  &__up-arrow {
    top: 714px;
  }

  &__watermark {
    min-height: 100%;
  }

  &__title {
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 2.125rem;
    line-height: 2.6875rem;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    @include above(laptop) {
      margin-top: 40px;
      font-size: 3rem;
      line-height: 3.8125rem;
    }

    @include above(desktop) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    @include above(tablet) {
      max-width: 327px;
    }

    @include above(laptop) {
      max-width: 912px;
    }

    @include above(desktop) {
      text-align: left;
    }
  }

  &__link {
    margin-top: 32px;
    padding: 16px 13.5px;

    @include above(laptop) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__list {
    @include above(tablet) {
      margin-top: 64px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 50px;
      row-gap: 64px;

      &--no-spacing {
        margin-top: 0;
      }
    }

    @include above(laptop) {
      column-gap: 64px;

      &--alt-grid {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 50px;
      }
    }

    @include above(desktop) {
      margin-top: 0;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 50px;

      &--alt-grid {
        grid-template-columns: 1fr 1fr;
        column-gap: 64px;
      }
    }
  }
}
