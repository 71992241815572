@import "../../../styles/tools/media-queries/above";

.verify {
  &__info {
    @include above(tablet) {
      display: flex;
      flex-flow: row;
    }
  }
}
