@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/core/z-index";

.footer-cta {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  background: #363636;

  &--sticky {
    position: fixed;
    width: 100vw;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: $z-footer-cta;
  }

  &--show {
    opacity: 1;
  }

  &--hide {
    opacity: 0;
  }

  @include above(tablet) {
    padding: 16px 32px;
  }

  @include above(desktop) {
    padding-left: 80px;
    padding-right: 80px;
  }

  &__desc {
    font-family: $made-tommy-bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    color: $iceberg;
  }

  &__link {
    padding: 12px 16px;
    flex-shrink: 0;
  }
}
