// Primary Colors
$indigo: #170f24;
$slate-blue: #2b263a;
$silver-grey: #808080;
$iceberg: #ffffff;

// Accent Colors
$lilac: #8400ff;
$cyan: #00fef0;
$chartreuse: #7fff00;
$fuchsia: #fe01e9;

// Gradients
$gradient-dark-ln: linear-gradient(90deg, $indigo 0%, $slate-blue 110%);

$gradient-grey-ln: linear-gradient(180deg, $silver-grey 0%, $iceberg 100%);

$gradient-grey-ln-reverse: linear-gradient(
  360deg,
  $silver-grey 0%,
  $iceberg 100%
);

// Gutters
$mobile-gutter: 24px;
$tablet-gutter: 36px;
$laptop-gutter: 32px;
$desktop-gutter: 80px;

// Border radius
$radious-default: 1.3rem;

// Max width
$maxwidth: 1440px;

// Svg Colors
$cross-vector-original: #2d2c2c;
$cross-vector-grey: #bebdbd;
$cross-vector-silver: #c4c3c3;
$cross-vector-light: #f8f8f8;
$cross-vector-dark: #302f2f;
$topographic-vector-original: #845858;
$topographic-square-original: #231f20;
$watermark-mobile: #1e1e1e;
$watermark-negative: #f3f3f3;
$watermark-value-list: #222d35;
$topography-value-list: #484445;
$right-arrow: #170f24;
$right-arrow-solid: #dbdbdb;
$linkedin: #313131;
$arrow-partial: #131c23;
