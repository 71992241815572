@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.tag {
  padding: 10px;
  border: 1px solid;

  &--Lilac {
    color: $lilac;
    border-color: $lilac;
  }

  &--Cyan {
    color: $cyan;
    border-color: $cyan;
  }

  &--Chartreuse {
    color: $chartreuse;
    border-color: $chartreuse;
  }

  &--Fuchsia {
    color: $fuchsia;
    border-color: $fuchsia;
  }

  &__title {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    text-transform: uppercase;
  }
}
