@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.rte-content {
  &__container {
    @include container;

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }

    &--no-spacing {
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 24px;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    p {
      margin-bottom: 24px;
      font-family: $made-tommy-light;
      font-size: 1rem;
      line-height: 1.25rem;

      @include above(desktop) {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }

    ul,
    ol {
      padding-left: 24px;
      margin-left: 1rem;
      margin-bottom: 24px;

      @include above(desktop) {
        margin-left: 1.5rem;
      }
    }

    ol {
      list-style-type: lower-alpha;
    }

    li {
      font-family: $made-tommy-light;
      font-size: 1rem;
      line-height: 1.25rem;

      @include above(desktop) {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }

    a,
    a:link,
    a:visited {
      color: $lilac;
    }

    a:hover,
    a:focus {
      color: $fuchsia;
    }

    img {
      max-width: 100%;
    }
  }
}
