@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.key-features {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 106px;
    left: 0;
    right: 0;
    background: black;
    z-index: -1;

    @include above(tablet) {
      bottom: 80px;
    }

    @include above(desktop) {
      bottom: 244px;
    }
  }

  &--small-bottom {
    @include above(desktop) {
      &::before {
        bottom: 171px;
      }
    }
  }

  &__background {
    position: relative;
    padding-top: 63px;
    padding-bottom: 46px;
    overflow: hidden;

    @include above(laptop) {
      padding-top: 83px;
    }

    @include above(desktop) {
      padding-bottom: 80px;
    }

    &--large-top {
      @include above(desktop) {
        padding-top: 146px;
        padding-bottom: 128px;
      }
    }
  }

  &__bottom-bg {
    position: relative;
    height: 106px;

    @include above(tablet) {
      height: 80px;
    }

    @include above(desktop) {
      height: 244px;
    }

    &--small {
      @include above(desktop) {
        height: 171px;
      }
    }
  }

  &__topography-secondary {
    position: absolute;
    bottom: 0;
    color: $topographic-square-original;
    opacity: 0.75;
    z-index: -1;
  }

  &__container {
    @include container;
    position: relative;
    max-width: $maxwidth;
  }

  &__list {
    margin-top: 32px;

    @include above(tablet) {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
    }
  }
}
