@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.carousel {
  $block: &;

  &--using-animation {
    .swiper-pagination,
    .swiper-scrollbar {
      transition: none;
    }

    .swiper-slide {
      opacity: 0;
      visibility: hidden;
    }
  }

  .swiper {
    padding-bottom: 25px;

    @include above(laptop) {
      padding-bottom: 37px;
    }
  }

  .swiper--align-top {
    .swiper-wrapper {
      align-items: stretch;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination {
    position: static;
    margin-top: 32px;

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      opacity: 1;
      border: 1px solid #3f3f3f;
      background: transparent;

      &.swiper-pagination-bullet-active::before {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: -23%;
        transform: translate(-50%);
        height: 21px;
        width: 21px;
        border: 1px solid #3f3f3f;
        border-radius: 100%;
        z-index: -1;

        @include above(laptop) {
          bottom: -26%;
        }
      }
    }
  }

  .swiper-scrollbar {
    bottom: 0;
    left: 0;
    width: calc(100vw - ($mobile-gutter * 2));
    background: rgba(#3f3f3f, 0.5);
    border-radius: 0;

    @include above(laptop) {
      width: calc(100vw - ($laptop-gutter * 2));
    }

    @include above(desktop) {
      width: calc(100vw - 160px);
    }

    @include above(maxwidth) {
      width: 1280px;
      left: 50%;
      transform: translateX(-50%);
    }

    .swiper-scrollbar-drag {
      background: #3f3f3f;
      border-radius: 0;
    }
  }

  &#{$block}--light {
    .swiper-pagination .swiper-pagination-bullet {
      border-color: $iceberg;

      &.swiper-pagination-bullet-active::before {
        border-color: $iceberg;
      }
    }

    .swiper-scrollbar {
      background: rgba($iceberg, 0.5);

      .swiper-scrollbar-drag {
        background: $iceberg;
      }
    }
  }
}
