@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.culture-features {
  position: relative;
  overflow: hidden;

  &__background {
    position: relative;
    padding-top: 40px;
    padding-bottom: 48px;

    @include above(tablet) {
      padding-bottom: 56px;
    }

    @include above(laptop) {
      padding-bottom: 64px;
    }

    @include above(desktop) {
      padding-bottom: 88px;
    }
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include above(desktop) {
      max-width: 73.9vw;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }

    @include above(maxwidth) {
      max-width: $maxwidth;
      padding: 0;
    }
  }

  &__watermark {
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    color: $arrow-partial;
    transition: none;
  }

  &__list {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    row-gap: 24px;

    @include above(tablet) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include above(laptop) {
      margin-top: 64px;
      padding-left: 73px;
      padding-right: 73px;
      row-gap: 32px;
      column-gap: 46px;
    }

    @include above(desktop) {
      margin-top: 61px;
      column-gap: 60px;
      padding-left: 111px;
      padding-right: 111px;
    }
  }
}
