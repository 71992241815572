@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.footer-legal {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 38px;
  border-top: 1px solid #4a4a4a;

  @include above(laptop) {
    order: 0;
    padding-top: 0;
    margin-right: auto;
    max-width: 310px;
    align-items: flex-start;
    border-top: none;
  }

  &__link {
    display: block;
    line-height: 1;
  }

  &__disclaimer,
  &__rights {
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;

    @include above(laptop) {
      text-align: left;
    }
  }

  &__disclaimer {
    margin-top: 38px;

    @include above(laptop) {
      margin-top: 32px;
    }
  }

  &__rights {
    margin-top: 32px;

    @include above(laptop) {
      margin-top: 116px;
    }
  }
}
