@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.contact-hero {
  $block: &;
  position: relative;
  background: white;
  overflow: hidden;

  @include above(laptop) {
    width: calc(100% - 584px);
    overflow: visible;
  }

  @include above(desktop) {
    width: 50%;
  }

  &__container {
    position: relative;
    display: grid;
    grid:
      [row1-start] ". . ." 191px [row1-end]
      [row2-start] ". headline ." 1fr [row2-end]
      [row3-start] ". . ." 74px [row3-end]
      [row4-start] "mask mask mask" 50px [row4-end]/24px auto 24px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 108px;
      width: 30vw;
      background: white;
    }

    @include above(tablet) {
      grid:
        [row1-start] ". . ." 180px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". . ." 42px [row3-end]
        [row4-start] "mask mask mask" 50px [row4-end]/24px auto 24px;
    }

    @include above(laptop) {
      height: 100%;
      grid:
        [row1-start] ". . . mask" 282px [row1-end]
        [row2-start] ". headline . mask" 1fr [row2-end]
        [row3-start] ". . . mask" 174px [row3-end]
        / 56px auto 20px 50px;

      &::before {
        content: none;
      }
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . . mask" 288px [row1-end]
        [row2-start] ". headline . mask" 1fr [row2-end]
        [row3-start] ". . . mask" 180px [row3-end]
        / 80px auto 120px 50px;
    }

    @include above(maxwidth) {
      grid:
        [row1-start] ". . . mask" 288px [row1-end]
        [row2-start] ". headline . mask" 1fr [row2-end]
        [row3-start] ". . . mask" 180px [row3-end]/1fr auto 120px 50px;
    }
  }

  &__headline {
    grid-area: headline;

    @include above(maxwidth) {
      max-width: 500px;
    }
  }

  &__title {
    font-family: $made-tommy-bold;
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: capitalize;
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;

    & p:not(:first-child) {
      margin-top: 1.25rem;
    }
  }

  &__row {
    display: flex;
    margin-top: 8px;

    &--spacing-top {
      margin-top: 32px;
    }
  }

  &__icon {
    &--phone {
      margin-left: 5px;
      margin-right: 17px;
    }
    &--email {
      margin-right: 12px;
    }
  }

  #{$block}__info {
    font-family: $made-tommy-medium;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    color: black;
  }

  &__social {
    margin-top: 32px;

    @include above(desktop) {
      margin-top: 40px;
    }
  }

  &__cross-vector {
    position: absolute;
    top: -25%;
    right: 5%;
    opacity: 0.3;
    color: $cross-vector-grey;

    @include above(tablet) {
      height: 300%;
      left: -90px;
      top: -340px;
      right: auto;
    }

    @include above(laptop) {
      height: 200%;
      top: -320px;
    }
  }
}
