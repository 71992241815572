@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.media-hero {
  position: relative;

  &__container {
    position: relative;
    display: grid;
    grid:
      [row1-start] ". . ." 190px [row1-end]
      [row2-start] ". headline ." auto [row2-end]
      [row3-start] ". filters ." auto [row3-end]
      [row4-start] ". . ." 125px [row4-end] / 24px auto 24px;
    justify-items: center;

    @include above(tablet) {
      grid:
        [row1-start] ". . ." 190px [row1-end]
        [row2-start] ". headline ." auto [row2-end]
        [row3-start] ". filters ." auto [row3-end]
        [row4-start] ". . ." 202px [row4-end] / 24px auto 24px;
    }

    @include above(laptop) {
      grid:
        [row1-start] ". . ." 160px [row1-end]
        [row2-start] ". headline ." auto [row2-end]
        [row3-start] ". filters ." auto [row3-end]
        [row4-start] ". . ." 156px [row4-end] / 24px auto 24px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . ." 290px [row1-end]
        [row2-start] ". headline ." auto [row2-end]
        [row3-start] ". filters ." auto [row3-end]
        [row4-start] ". . ." 162px [row4-end] / 24px auto 24px;
    }
  }

  &__image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    grid-area: headline;
    font-family: $made-tommy-black;
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-align: center;
    color: $iceberg;
    text-transform: uppercase;

    @include above(laptop) {
      font-size: 2.5rem;
      line-height: 3.125rem;
    }
  }

  &__filter-container {
    grid-area: filters;
    margin-top: 24px;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    color: $iceberg;
  }

  &__filter-group {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 16px;

    @include above(laptop) {
      flex-flow: row;
      gap: 24px;
    }
  }

  &__filter-title {
    font-family: $made-tommy-bold;
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }

  &__filter-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 16px;
  }

  &__button {
    padding: 8px 16px;
    font-family: $made-tommy;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    &--active {
      background-color: $iceberg;
      color: $indigo;
    }
  }
}
