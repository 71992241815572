@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.highlighted-info {
  position: relative;

  &__bg-container {
    position: absolute;
    top: -50px;
    left: 0;
    bottom: -50px;
    width: 100%;
    overflow: hidden;
    z-index: 2;
  }

  &__container {
    padding-top: 76px;
    padding-bottom: 84px;

    @include above(tablet) {
      padding-top: 110px;
      padding-bottom: 110px;
    }

    @include above(desktop) {
      padding-top: 130px;
      padding-bottom: 130px;
    }
  }

  &__content-container {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include above(tablet) {
      max-width: 326px;
      padding: 0;
    }

    @include above(laptop) {
      max-width: 470px;
    }

    @include above(desktop) {
      max-width: 414px;
    }
  }

  &__title {
    font-family: $made-tommy-black;
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-transform: uppercase;

    @include above(laptop) {
      font-family: $made-tommy-bold;
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-size: 4rem;
      line-height: 3.5rem;
    }
  }

  &__desc {
    margin-top: 16px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;

    @include above(laptop) {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }

    @include above(desktop) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }

  &__cross-vector {
    position: absolute;
    opacity: 0.2;
    z-index: -1;
    overflow: hidden;
    top: 41%;
    height: 200%;

    &--left {
      left: -230px;
      transform: translateY(-50%);
    }

    &--right {
      right: -230px;
      transform: scaleX(-1) translateY(-50%);
    }

    @include above(tablet) {
      top: 50%;
      height: 200%;

      &--left {
        left: -100px;
        transform: translateY(-50%);
      }
      &--right {
        right: -100px;
        transform: translateY(-50%) scaleX(-1);
      }
    }

    @include above(laptop) {
      &--left {
        left: -150px;
      }
      &--right {
        right: -150px;
      }
    }

    @include above(desktop) {
      &--left {
        left: -20px;
      }
      &--right {
        right: -20px;
      }
    }
  }
}
