@import "../../styles/tools/media-queries/above";
@import "../../styles/tools/media-queries/below";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.footer-sitemap {
  $block: &;
  margin-bottom: 32px;

  @include above(laptop) {
    order: 1;
    margin-bottom: 0;
    margin-right: 20px;
    margin-left: 20px;
  }

  @include above(desktop) {
    margin-left: 0;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;

    @include above(laptop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__category {
    &:first-child {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;

      @include above(laptop) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
    }

    &:last-child {
      grid-row: 1 / span 1;
    }
  }

  &__title {
    font-family: $made-tommy-bold;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &__list {
    margin-top: 32px;
    list-style-type: none;
  }

  &__list-item {
    line-height: 1.25rem;

    &:not(:first-child) {
      margin-top: 40px;

      @include above(laptop) {
        margin-top: 32px;
      }
    }
  }

  &__link {
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
