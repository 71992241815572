@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.job-hero {
  position: relative;

  &__container {
    display: grid;
    grid:
      [row1-start] ". . ." 261px [row1-end]
      [row2-start] ". headline ." 1fr [row2-end]
      [row3-start] ". . ." 40px [row3-end]
      / 24px auto 24px;

    @include above(tablet) {
      grid:
        [row1-start] ". . ." 285px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". . ." 40px [row3-end]/24px 1fr 24px;
    }

    @include above(laptop) {
      grid:
        [row1-start] ". . ." 253px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". . ." 56px [row3-end]/24px 1fr 24px;
    }

    @include above(desktop) {
      grid:
        [row1-start] ". . ." 253px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". . ." 56px [row3-end]/80px 1fr 80px;
    }

    @include above(maxwidth) {
      grid:
        [row1-start] ". . ." 253px [row1-end]
        [row2-start] ". headline ." 1fr [row2-end]
        [row3-start] ". . ." 56px [row3-end]/1fr 1280px 1fr;
    }
  }

  &__headline {
    grid-area: headline;
    color: $iceberg;
  }

  &__tag-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 8px;
  }

  &__title {
    font-weight: 800;
    font-size: 2.125rem;
    line-height: 1.75rem;

    @include above(tablet) {
      font-size: 2.5rem;
      line-height: 2rem;
    }

    @include above(laptop) {
      font-size: 3.25rem;
      line-height: 3rem;
    }
  }
}
