@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.leadership-team {
  position: relative;

  &__background {
    position: relative;
    padding-top: 63px;
    padding-bottom: 20px;
    overflow: hidden;

    @include above(laptop) {
      padding-bottom: 48px;
    }
  }

  &__container {
    position: relative;
    z-index: 1;

    @include above(maxwidth) {
      max-width: $maxwidth;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__member-list {
    position: relative;
    margin-top: 50px;
    padding-left: $mobile-gutter;

    @include above(laptop) {
      padding-left: $laptop-gutter;
    }

    @include above(desktop) {
      margin-top: 40px;
      padding-left: 80px;
    }

    @include above(maxwidth) {
      padding-left: 0;
    }
  }
}
