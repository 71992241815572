@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.title-accent-line {
  display: flex;
  flex-flow: column;
  align-items: center;

  &__title {
    font-family: $made-tommy;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;

    @include above(desktop) {
      font-size: 2.5rem;
      line-height: 3.125rem;
    }

    &--dark {
      color: black;
    }
    &--light {
      color: $iceberg;
    }
  }

  &__line {
    margin-bottom: 12px;
    height: 87px;
    width: 1px;

    &--dark {
      background-color: black;
    }
    &--light {
      background-color: $iceberg;
    }
  }
}
