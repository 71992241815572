@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.info-panel {
  $block: &;
  background-color: $iceberg;

  &:not(:first-child) {
    margin-top: 32px;
  }

  @include above(tablet) {
    flex: 1;
    flex-basis: 45%;

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  @include above(desktop) {
    flex-basis: 20%;
  }

  &--alternate {
    opacity: 0;
    visibility: none;
    transition: none;

    &#{$block}--even {
      #{$block}__watermark {
        transform: scaleX(-1) translateY(-50%);

        @include above(laptop) {
          left: 125px;
          right: auto;
        }

        @include above(desktop) {
          left: 158px;
        }
      }
    }

    @include above(tablet) {
      display: flex;
      flex-flow: row;
      max-width: 69.6vw;

      &#{$block}--even {
        margin-left: auto;

        #{$block}__cover {
          order: 0;
        }
      }
    }

    @include above(laptop) {
      max-width: 69.8vw;
    }
  }

  &--use-animation {
    @include above(laptop) {
      background-color: transparent;
    }
  }

  &__cover {
    position: relative;
    color: $iceberg;

    #{$block}__title {
      position: absolute;
      left: 24px;
      bottom: 12px;
      font-size: 2.5rem;
      line-height: 3.1875rem;

      @include above(laptop) {
        font-size: 3.125rem;
        line-height: 4rem;
      }

      &--small {
        font-size: 1.75rem;
        line-height: 1.75rem;

        @include above(tablet) {
          max-width: 252px;
        }

        @include above(laptop) {
          max-width: 90%;
          font-size: 1.75rem;
          line-height: 1.75rem;
        }

        @include above(desktop) {
          max-width: 70%;
        }
      }
    }

    #{$block}--alternate & {
      @include above(tablet) {
        width: 50%;
        order: 1;
      }

      @include above(laptop) {
        width: 320px;
      }

      @include above(desktop) {
        width: 480px;
      }
    }

    #{$block}--use-animation & {
      height: 196px;
    }

    #{$block}--use-animation#{$block}--alternate & {
      @include above(laptop) {
        height: 320px;
      }
      @include above(desktop) {
        height: 440px;
      }
    }
  }

  &__cover-img {
    display: block;
    width: 100%;
    height: 196px;
    background-size: 101% 101%;
    background-repeat: no-repeat;
    background-position: center;

    &:hover,
    &:focus {
      background-size: 105% 105%;
    }

    #{$block}--alternate & {
      @include above(tablet) {
        height: 270px;
      }
      @include above(laptop) {
        height: 320px;
      }
      @include above(desktop) {
        height: 440px;
      }
    }

    #{$block}--use-animation & {
      @include above(laptop) {
        height: 0;
      }
    }
  }

  &__container {
    position: relative;
    padding: 20px;
    color: $indigo;
    overflow: hidden;

    #{$block}--alternate & {
      display: flex;
      flex-flow: column;
      justify-content: center;
      background: black;

      @include above(tablet) {
        width: 50%;
        padding: 34px 24px;
      }

      @include above(laptop) {
        padding: 21px 32px;
        width: 395px;
      }

      @include above(desktop) {
        padding: 21px 64px;
        width: 475px;
      }
    }

    #{$block}--use-animation & {
      @include above(laptop) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__title {
    position: relative;
    font-family: $made-tommy-black;
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    z-index: 1;

    #{$block}--alternate & {
      color: $iceberg;

      @include above(tablet) {
        font-size: 1.5rem;
        line-height: 1.9375rem;
      }

      @include above(laptop) {
        font-size: 1.9375rem;
      }
    }

    #{$block}--use-animation & {
      @include above(laptop) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__desc {
    position: relative;
    font-family: $made-tommy;
    font-size: 1.125rem;
    line-height: 1.375rem;
    z-index: 1;

    &:not(:first-child) {
      margin-top: 16px;
    }

    @include above(laptop) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }

    &--small {
      @include above(laptop) {
        font-size: 1.25rem;
        line-height: 1.5625rem;
      }
    }

    #{$block}--alternate & {
      font-family: $made-tommy-light;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $iceberg;

      @include above(tablet) {
        font-size: 0.875rem;
        line-height: 1.0625rem;
      }
      @include above(laptop) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  &__watermark {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    color: $right-arrow;
    z-index: 0;

    @include above(laptop) {
      left: auto;
      right: 125px;
    }

    @include above(desktop) {
      right: 158px;
    }
  }
}
