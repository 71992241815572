@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";
@import "../../styles/tools/layout/container";

.blog-content {
  &__container {
    @include container;

    @include above(laptop) {
      padding: 0;
    }
  }

  &__header {
    padding-top: 40px;
    border-top: 1px solid #d3d3d3;
    font-family: $made-tommy-light;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #000000;

    &--alternate {
      padding-top: 48px;
      border-top: none;
    }

    @include above(tablet) {
      padding-top: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include above(laptop) {
      &--alternate {
        padding-top: 0;
      }
    }

    @include above(desktop) {
      padding-top: 0;
      border-top: none;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 16px;

    @include above(tablet) {
      order: 1;
    }

    &--footer {
      flex-flow: column;
    }
  }

  &__date {
    margin-top: 16px;

    @include above(tablet) {
      order: 0;
      margin-top: 0;
    }
  }

  &__content {
    margin-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #d3d3d3;
  }

  &__title {
    margin-bottom: 28px;
    font-family: $made-tommy-black;
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    color: #000000;

    @include above(laptop) {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }

  &__footer {
    margin-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #d3d3d3;
    text-align: center;
    font-family: $made-tommy-light;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #000000;
  }
}
