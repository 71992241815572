@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.policy-hero {
  $block: &;
  position: relative;
  padding-top: 150px;
  margin-bottom: 24px;

  @include above(desktop) {
    padding-top: 180px;
  }

  @include above(maxwidth) {
    max-width: $maxwidth;
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    @include container;
  }

  &__title {
    font-size: 2.125rem;
    line-height: 1.75rem;

    @include above(laptop) {
      font-size: 3rem;
      line-height: 3.75rem;
    }
  }

  &__prefix {
    margin-bottom: 24px;
    font-family: $made-tommy-light;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
