@import "../../core/scss-variables";
@import "../../core/fonts";

%button-minimum {
  padding: 16px 34px;
  border: 1px solid $iceberg;
  background: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
  white-space: nowrap;
  color: $iceberg;
  transition: color 0.3s ease-in, background-color 0.3s ease-in,
    border-color 0.3s ease-in;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: $iceberg;
    color: black;
  }
}

%button {
  @extend %button-minimum;
  text-transform: capitalize;
  font-family: $made-tommy-medium;
}

@mixin button {
  @extend %button;
}

@mixin button-minimal {
  @extend %button-minimum;
}

@mixin button-solid {
  @extend %button;
  background-color: $iceberg;
  color: black;

  &:link,
  &:visited {
    color: black;
  }

  &:hover,
  &:focus {
    background-color: black;
    border-color: black;
    color: $iceberg;
  }
}

@mixin button-dark {
  @extend %button;
  color: $indigo;
  border-color: $indigo;

  &:link,
  &:visited {
    color: $indigo;
  }

  &:hover,
  &:focus {
    background-color: $indigo;
    color: $iceberg;
  }
}
