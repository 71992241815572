@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";

.footer-media {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid #4a4a4a;

  @include above(laptop) {
    order: 2;
    padding-top: 0;
    margin-bottom: 0;
    border-top: none;
  }
}
