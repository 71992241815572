@import "../../styles/tools/layout/container";
@import "../../styles/tools/media-queries/above";
@import "../../styles/core/scss-variables";
@import "../../styles/core/fonts";

.decode-title {
  $block: &;

  &__title {
    font-size: 2.125rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    color: $topographic-square-original;

    @include above(laptop) {
      font-size: 3.25rem;
      line-height: 3rem;
    }

    @include above(desktop) {
      font-size: 5rem;
      line-height: 4.5rem;
    }

    #{$block}--alt & {
      @include above(desktop) {
        font-size: 4rem;
        line-height: 3.5rem;
      }
    }

    #{$block}--product & {
      @include above(laptop) {
        font-size: 2.75rem;
        line-height: 2.5rem;
      }

      @include above(desktop) {
        font-family: $made-tommy-black;
        font-size: 3rem;
        line-height: 2.625rem;
      }
    }
  }

  &__space {
    // TODO fix wordbreak issue caused by characters being wrapped, if set to block then breaks on space
    display: inline-block;
    width: 1rem;
  }

  &__character {
    display: inline-block;
    position: relative;
    color: transparent;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background: $fuchsia;
      width: 0;
      height: 120%;
      transform: translate(-50%, -55%);
      transition: none;
    }

    &.state-1 {
      &::before {
        width: 5px;
      }
    }
    &.state-2 {
      &::before {
        width: 100%;
        background: $cyan;
      }
    }
    &.state-3 {
      color: $topographic-square-original;

      &::before {
        width: 0;
      }
    }
  }
}
